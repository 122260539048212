import * as React from "react";
import styles from "./Projects.module.css";

const Projects = () => {
  return (
    <div className={styles.container}>
      <a href="/to-brancusi">
        <img src="/projects2.jpg" loading={"lazy"}></img>
        <div className={styles.imgText}>To Brancusi...</div>
      </a>
      <a href="/gmaro-magazine">
        <img src="/gmaro5.png" loading={"lazy"}></img>
        <div className={styles.imgText}>GMARO Magazine Editorial</div>
      </a>
      <a href="https://flavianis.com">
        <img src="/projects1.jpg" loading={"lazy"}></img>
        <div className={styles.imgText}>FlaviaNís</div>
      </a>
      <a href="/apres-chic">
        <img src="/projects3.jpg" loading={"lazy"}></img>
        <div className={`${styles.imgText} ${styles.white}`}>Après-Chic</div>
      </a>
    </div>
  );
};

export default Projects;
