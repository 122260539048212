export const photos = [
  {
    src: '/homeb2.jpg',
    infoTitle: 'Newborn Outfit 3 jacket close-up; To Brancusi...',
    infoDescription: 'Proving complex and innovative design, creative pattern cutting and styling skills',
  },
  {
    src: '/homeb111.png',
    infoTitle: 'Bird in Space Outfit; To Brancusi...',
    infoDescription: 'Proving complex and innovative design, creative pattern cutting and styling skills',
  },
  {
    src: '/homeb3.JPG',
    infoTitle: 'Infinity Column Dress Outfit 4; To Brancusi...',
    infoDescription: 'Proving complex and innovative design, creative pattern cutting and styling skills',
  },
  {
    src: '/research.png',
    infoTitle: 'Research page; To Brancusi...',
    infoDescription: 'Research, design development and hand-drawing skills',
  },
  {
    src: '/home1.png',
    infoTitle: 'Design development page; To Brancusi...',
    infoDescription: 'Research, design development and hand-drawing skills',
  },
  {
    src: '/brancusi14.png',
    infoTitle: 'Illustration Final Layout; To Brancusi...',
    infoDescription: 'Proving digital drawing skills',
  },
  {
    src: '/home6.jpeg',
    infoTitle: 'FlaviaNis silk corset; SS\'22 collection',
    infoDescription: 'Proving both complex construction and styling skills',
  },
  {
    src: '/home2.png',
    infoTitle: 'FlaviaNis Tinkerbell Ruby Dress; SS\'22 collection',
    infoDescription: 'Proving editing skills using Lightroom and Photoshop',
  },
  {
    src: '/home7.jpg',
    infoTitle: 'FlaviaNis illustrations',
    infoDescription: 'Proving hand-drawing skills',
  },
  {
    src: '/img3.jpg',
    infoTitle: 'FlaviaNis Cecilia Dress',
    infoDescription: 'Luxury designs, pattern cutting and styling skills',
  },
  {
    src: '/home40.jpg',
    infoTitle: 'FlaviaNis corset',
    infoDescription: 'SS\'22 collection featured in GMARO Magazine Paris Fashion designs, pattern cutting and styling skills',
  },
  {
    src: '/home50.jpg',
    infoTitle: 'Corset collection FlaviaNis',
    infoDescription: 'Luxury designs, pattern cutting and styling skills',
  }
];

export const recognitionPhotos = [
  {
    src: '/reco1.jpeg',
    infoTitle: 'Graduate Fashion Week',
    infoDescription: 'To Brancusi... collection photographed by Olu Ogunshakin',
  },
  {
    src: '/reco2.jpeg',
    infoTitle: 'Graduate Fashion Week',
    infoDescription: 'To Brancusi... collection photographed by Olu Ogunshakin',
  },
  {
    src: '/gmaro6.jpg',
    infoTitle: 'Muses of Brancusi',
    infoDescription: 'Photographed by Roxana Georgescu, Model: Teodora Gheorghe, Make-up and Hair Stylist: Maria Neagu, Fashion Design & Styling: Flavia Nistor',
  },
];

export const gmaroPhotos = [
  {
    src: '/gmaro3.png',
    infoTitle: 'GMARO Page 33',
    infoDescription: 'Aug 22 Issue',
    isHalfImg: true
  },
  {
    src: '/gmaro4.png',
    infoTitle: 'GMARO Page 35',
    infoDescription: 'Aug 22 Issue',
    isHalfImg: true
  },
  {
    src: '/gmaro5.png',
    infoTitle: 'GMARO Page 36',
    infoDescription: 'Aug 22 Issue',
    isHalfImg: true
  },
  {
    src: '/gmaro2.png',
    infoTitle: 'GMARO Page 44-45',
    infoDescription: 'Aug 23 Issue',
    isFullWidth: true
  },
  {
    src: '/gmaro1.png',
    infoTitle: 'GMARO Page 30-31',
    infoDescription: 'Aug 22 Issue',
  },
  {
    src: '/gmaro6.png',
    infoTitle: 'GMARO Page 38-39',
    infoDescription: 'Aug 23 Issue',
  }
]

export const toBrancusiPhotos = [
  {
    src: '/brancusi3.jpg',
    infoTitle: 'Concept and customer boards',
    infoDescription: '',
  },
  {
    src: '/brancusi5.jpg',
    infoTitle: 'Design development',
    infoDescription: '',
  },
	{
		src: '/home3.png',
		infoTitle: 'Toile development',
		infoDescription: 'Defining the draped silhouette and developing textures',
	},
  {
    src: '/brancusi10.jpg',
    infoTitle: 'Comparative report on Schiaparelli',
    infoDescription: '',
  },
  {
    src: '/brancusi12.jpg',
    infoTitle: 'Texture and laser experiments board',
    infoDescription: '',
  },
  {
    src: '/brancusi13.png',
    infoTitle: 'CAD for Newborn Outfit 3',
    infoDescription: '',
    isFullWidth: true,
  },
  {
    src: '/fmp2.png',
    infoTitle: 'Ifinity Column Outfit 4',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/fmp3.png',
    infoTitle: 'Infinity Column & Mlle. Pogany Outfits close-up',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/brancusin2.png',
    infoTitle: 'Catwalk @ London Graduate Fashion Week',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/fmp7.png',
    infoTitle: 'Newborn Outfit 3',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/fmp9.png',
    infoTitle: 'Newborn Outfit 3 close-up',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/fmp11.png',
    infoTitle: 'Newborn Outfit 3 collar detail',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/fmp12.png',
    infoTitle: 'Bird in Space Outfit 1',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/fmp16.png',
    infoTitle: 'Bird in Space Outfit 1 close-up',
    infoDescription: '',
    isHalfImg: true
  },
  {
    src: '/brancusin1.png',
    infoTitle: 'To Brancusi Exhibition',
    infoDescription: 'Raluca Turcan, Ministry of Culture visits my exhibition in Timisoara, the European Capital of Culture 2023',
  },
  {
    src: '/brancusin3.png',
    infoTitle: 'Catwalk @ London Graduate Fashion Week',
    infoDescription: '',
  },
]

export const apresChicPhotos = [
  {
    src: '/ac12',
    infoTitle: 'Concept and customer board',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac11',
    infoTitle: 'Research page',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac10',
    infoTitle: 'Fabric Research & Design Development',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac9',
    infoTitle: 'Comparative Report on Irina Schrotter',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac1.jpg',
    infoTitle: 'Final Outfit 1',
    infoDescription: '',
    isPortrait: true
  },
  {
    src: '/ac2.jpg',
    infoTitle: 'Outfit 1 trousers',
    infoDescription: '',
    isPortrait: true
  },
  {
    src: '/ac3',
    infoTitle: 'Final Design Board Outfit 1',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac4.jpg',
    infoTitle: 'Outfit 1 close-up',
    infoDescription: '',
    isPortrait: true
  },
  {
    src: '/ac8.jpg',
    infoTitle: 'Outfit 1 jacket',
    infoDescription: '',
    isPortrait: true
  },
  {
    src: '/ac5',
    infoTitle: 'Outfit 1 illustration and photoshoot image',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac6',
    infoTitle: 'Final Design Board Outfit 2',
    infoDescription: '',
    isSrcSet: true
  },
  {
    src: '/ac7',
    infoTitle: 'Outfit 2 Crater Sleeve Dress',
    infoDescription: '',
    isSrcSet: true
  }
]

export type HoverableImageProps = {
  src: string;
  infoTitle: string;
  infoDescription: string;
  isHalfImg?: boolean;
  isFullWidth?: boolean;
  isPortrait?: boolean;
  isLandscape?: boolean;
  isSrcSet?: boolean;
}
