import * as React from "react";
import { HoverableImageProps } from "../../constants/photos";
import styles from "./HoverableImage.module.css";

export function HoverableImage({
  src,
  infoDescription,
  infoTitle,
  isHalfImg,
  isFullWidth,
  isPortrait,
  isSrcSet,
}: HoverableImageProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <div
      className={`${styles.imageWrapper} ${
        isPortrait ? `${styles.portrait}` : ""
      }`}
      onMouseEnter={setHover}
      onMouseLeave={setUnhover}
    >
      {isSrcSet ? (
        <img
          srcSet={`${src}m.png 300w, ${src}d.png 600w`}
          sizes="(max-width: 1084px) 300px, 600px"
          src={`${src}d.png`}
          className={`${isHalfImg ? `${styles.smallImg}` : ""} ${
            isFullWidth ? `${styles.fullWidth}` : ""
          }`}
          loading={"lazy"}
        />
      ) : (
        <img
          src={src}
          className={`${isHalfImg ? `${styles.smallImg}` : ""} ${
            isFullWidth ? `${styles.fullWidth}` : ""
          }`}
          loading={"lazy"}
        />
      )}
      {isHovered && (
        <div className={styles.imageOverlay}>
          <i>{infoTitle}</i>
          <i>{infoDescription}</i>
        </div>
      )}
    </div>
  );

  function setHover() {
    setIsHovered(true);
  }

  function setUnhover() {
    setIsHovered(false);
  }
}
