import * as React from "react";
import { HoverableImage } from "../../components/HoverableImage/HoverableImage";
import { photos } from "../../constants/photos";
import styles from "./Home.module.css";

const Home = () => {
  const gallery = photos.map((image) => (
    <HoverableImage
      src={image.src}
      infoTitle={image.infoTitle}
      infoDescription={image.infoDescription}
    />
  ));

  return (
    <div className={styles.galleryContainer}>
      <div className={styles.desktopGalleryContainer}>{gallery}</div>
      <div className={styles.mobileGalleryContainer}>{gallery}</div>
    </div>
  );
};

export default Home;
