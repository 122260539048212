import * as React from "react";
import { HoverableImage } from "../../components/HoverableImage/HoverableImage";
import { gmaroPhotos } from "../../constants/photos";
import styles from "./GMAROMagazine.module.css";

const GMAROMagazine = () => {
  const gallery = gmaroPhotos.map((image) => (
    <HoverableImage
      src={image.src}
      infoTitle={image.infoTitle}
      infoDescription={image.infoDescription}
      isHalfImg={image.isHalfImg}
      isFullWidth={image.isFullWidth}
    />
  ));

  return (
    <>
      <h2 className={styles.title}>GMARO Magazine Editorial</h2>
      <p className={styles.description}>
        Outfits created by me for my small brand, FlaviaNis, featured in GMARO
        Magazine Issue Aug '22.
      </p>
      <div className={styles.galleryContainer}>
        <div className={styles.desktopGalleryContainer}>{gallery}</div>
        <div className={styles.mobileGalleryContainer}>{gallery}</div>
      </div>
      <p className={styles.description}>
        <b>Credits: </b>
      </p>
      <p className={styles.description}>Photography: Roxana Georgescu</p>
      <p className={styles.description}>Model: Teodora Gheorghe</p>
      <p className={styles.description}>Make-up, Hair, Styling: Maria Neagu</p>
      <p className={styles.description}>Fashion Design: me</p>
    </>
  );
};

export default GMAROMagazine;
