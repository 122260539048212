import * as React from "react";
import { HoverableImage } from "../../components/HoverableImage/HoverableImage";
import { apresChicPhotos } from "../../constants/photos";
import styles from "./ApresChic.module.css";

const ApresChic = () => {
  const gallery = apresChicPhotos.map((image) => (
    <HoverableImage
      src={image.src}
      infoTitle={image.infoTitle}
      infoDescription={image.infoDescription}
      isPortrait={image.isPortrait}
      isSrcSet={image.isSrcSet}
    />
  ));

  return (
    <>
      <h2 className={styles.title}>Après-Chic</h2>
      <p className={styles.description}>
        Second year project for winter sportswear translated into high-end,
        multifunctional garments. Featuring Japanese creative pattern cutting
        details.
      </p>
      <div className={styles.galleryContainer}>
        <div className={styles.desktopGalleryContainer}>{gallery}</div>
        <div className={styles.mobileGalleryContainer}>{gallery}</div>
      </div>
    </>
  );
};

export default ApresChic;
