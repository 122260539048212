import * as React from "react";
import { BrowserRouter as Router, Routes, Link, Route } from "react-router-dom";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import CV from "./pages/CV/CV";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Projects from "./pages/Projects/Projects";
import Recognition from "./pages/Recognition/Recognition";
import Footer from "./components/Footer/Footer";
import ToBrancusi from "./pages/ToBrancusi/ToBrancusi";
import GMAROMagazine from "./pages/GMAROMagazine/GMAROMagazine";
import FlaviaNis from "./pages/FlaviaNis/FlaviaNis";
import ApresChic from "./pages/ApresChic/ApresChic";
import "./styles.css";

export default function App() {
  return (
    <Router>
      <div className="page">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/recognition" element={<Recognition />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/to-brancusi" element={<ToBrancusi />} />
          <Route path="/gmaro-magazine" element={<GMAROMagazine />} />
          <Route path="/flavianis" element={<FlaviaNis />} />
          <Route path="/apres-chic" element={<ApresChic />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
