import * as React from "react";
import { recognitionPhotos } from "../../constants/photos";

import styles from "./Recognition.module.css";
import { HoverableImage } from "../../components/HoverableImage/HoverableImage";

const Recognition = () => {
  const gallery = recognitionPhotos.map((image) => (
    <HoverableImage
      src={image.src}
      infoTitle={image.infoTitle}
      infoDescription={image.infoDescription}
    />
  ));
  return (
    <div className={styles.recognitionContainer}>
      <div className={styles.textContainer}>
        <div className={styles.gmaro}>
          <img className={styles.logo} src="/gmaro.jpg" loading={"lazy"}></img>
          <span>GMARO Magazine Aug '23</span>
        </div>
        <div className={styles.collective}>
          <img
            className={styles.logo}
            src="/gfwlogo.jpg"
            loading={"lazy"}
          ></img>
          <span>Graduate Fashion Week Collective Fashion Show '23</span>
        </div>
        <div className={styles.gfw}>
          <img
            className={styles.logo}
            src="/gfwlogo.jpg"
            loading={"lazy"}
          ></img>
          <span>Talent of Tomorrow Photoshoot '23</span>
        </div>
        <div className={styles.gmaro}>
          <img className={styles.logo} src="/gmaro.jpg" loading={"lazy"}></img>
          <span>GMARO Magazine Aug '22</span>
        </div>
        <div className={styles.dallesgo}>
          <img
            className={styles.logo}
            src="/dallesgo.jpg"
            loading={"lazy"}
          ></img>
          <span>Dalles Go Fashion Show '19</span>
        </div>
      </div>
      <div className={styles.imagesContainer}>
        <div className={styles.galleryContainer}>
          <div className={styles.desktopGalleryContainer}>{gallery}</div>
          <div className={styles.mobileGalleryContainer}>{gallery}</div>
        </div>
      </div>
    </div>
  );
};

export default Recognition;
