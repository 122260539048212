import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "../icons/CloseIcon";
import OpenIcon from "../icons/OpenIcon";
import styles from "./MobileHeader.module.css";

const MobileHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);

  return (
    <nav className={styles.navContainerWrapper}>
      <button
        type="button"
        className={styles.openCloseButtons}
        onClick={isMenuOpen ? closeMenu : openMenu}
      >
        {isMenuOpen ? <CloseIcon /> : <OpenIcon />}
      </button>
      <div className={`${styles.mobileNavContainer}`}>
        <Link to={"/"}>
          <div className={styles.logoMobileContainer}>
            <img
              src={"/logo.png"}
              alt="logo icon"
              width="250"
              height="50"
            ></img>
          </div>
        </Link>
      </div>
      {isMenuOpen ? (
        <ul className={styles.menuItems}>
          <li onClick={closeMenu}>
            <Link to={"/"}>MAIN PAGE</Link>
          </li>
          <hr />
          <li onClick={closeMenu}>
            <Link to={"/projects"}>PROJECTS</Link>
          </li>
          <hr />
          <li onClick={closeMenu}>
            <Link to={"/about"}>ABOUT</Link>
          </li>
          <hr />
          <li onClick={closeMenu}>
            <Link to={"/recognition"}>RECOGNITION</Link>
          </li>
          <hr />
          <li onClick={closeMenu}>
            <Link to={"/contact"}>CONTACT</Link>
          </li>
          <hr />
          <li onClick={closeMenu}>
            <Link to={"/CVFlavia.pdf"} target={"_blank"}>
              CV
            </Link>
          </li>
          <li onClick={closeMenu} className={styles.socialLinks}>
            <Link
              to={"https://www.instagram.com/flavia.nistor.portfolio/"}
              target="_blank"
            >
              <img
                src={"/instagram.png"}
                alt="instagram icon"
                width="20"
                height="20"
              ></img>
            </Link>
            <Link
              to={"https://www.linkedin.com/in/flavia-nistor-832563185/"}
              target="_blank"
            >
              <img
                src={"/linkedin.png"}
                alt="linkedin icon"
                width="20"
                height="20"
              ></img>
            </Link>
          </li>
        </ul>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default MobileHeader;
