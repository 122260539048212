import * as React from "react";
import styles from "./Contact.module.css";

const Contact = () => {
  return (
    <div className={styles.contactContainer}>
      <h2>Let's get in touch!</h2>
      <p>Email: contact@flavianis.com</p>
      <p>
        <a
          href="https://www.instagram.com/flavia.nistor.portfolio/"
          target="_blank"
        >
          Follow my portfolio account on Instagram
        </a>
      </p>
      <a
        href="https://www.linkedin.com/in/flavia-nistor-832563185/"
        target="_blank"
      >
        Connect with me on LinkedIn
      </a>
    </div>
  );
};

export default Contact;
