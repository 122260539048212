import React from "react";
import { Link } from "react-router-dom";
import styles from "./DesktopHeader.module.css";

const DesktopHeader = () => {
  return (
    <nav className={`${styles.desktopNavContainer}`}>
      <Link to={"/"} className={styles.logoDesktopContainer}>
        <img src={"/logo.png"} alt="logo icon" width="200" height="40"></img>
      </Link>
      <div className={styles.rightContainer}>
        <Link to={"/"} className={styles.link}>
          <span>MAIN PAGE</span>
        </Link>
        <Link to={"/projects"} className={styles.link}>
          <span>PROJECTS</span>
        </Link>
        <Link to={"/about"} className={styles.link}>
          <span>ABOUT</span>
        </Link>
        <Link to={"/recognition"} className={styles.link}>
          <span>RECOGNITION</span>
        </Link>
        <Link to={"https://flavianis.com"} className={styles.link}>
          <span>FLAVIANÍS</span>
        </Link>
        <Link to={"/contact"} className={styles.link}>
          <span>CONTACT</span>
        </Link>
        <Link to={"/CVFlavia.pdf"} target={"_blank"} className={styles.link}>
          <span>CV</span>
        </Link>
        <Link
          to={"https://www.instagram.com/flavia.nistor.portfolio/"}
          target="_blank"
        >
          <img
            src={"/instagram.png"}
            alt="instagram icon"
            width="20"
            height="20"
          ></img>
        </Link>
        <Link
          to={"https://www.linkedin.com/in/flavia-nistor-832563185/"}
          target="_blank"
        >
          <img
            src={"/linkedin.png"}
            alt="linkedin icon"
            width="20"
            height="20"
          ></img>
        </Link>
      </div>
    </nav>
  );
};

export default DesktopHeader;
