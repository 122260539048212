import * as React from "react";
import { HoverableImage } from "../../components/HoverableImage/HoverableImage";
import { toBrancusiPhotos } from "../../constants/photos";
import styles from "./ToBrancusi.module.css";

const ToBrancusi = () => {
  const gallery = toBrancusiPhotos.map((image) => (
    <HoverableImage
      src={image.src}
      infoTitle={image.infoTitle}
      infoDescription={image.infoDescription}
      isHalfImg={image.isHalfImg}
      isFullWidth={image.isFullWidth}
    />
  ));

  return (
    <>
      <h2 className={styles.title}>To Brancusi...</h2>
      <p className={styles.description}>
        The idea behind my FMP is to create cultural fashion designs. I based my
        research on Constantin Brancusi, a Romanian sculptor and the pioneer of
        Minimalism and Modernism. The reason for choosing this concept is to
        create outfits that cannot be evaluated through purely aesthetic
        judgment, but also through the substance, which is the cultural side.
        This high-end collection aims to remove the preconceptions of
        "superficiality" in fashion, promoting values and complex concepts,
        while commemorating universal geniuses like Constantin Brancusi.
      </p>
      <div className={styles.galleryContainer}>
        <div className={styles.desktopGalleryContainer}>{gallery}</div>
        <div className={styles.mobileGalleryContainer}>{gallery}</div>
      </div>
      <p className={styles.description}>
        Photography: Shawn James (catwalk) and Roxana Georgesu (other)
      </p>
      <p className={styles.description}>
        Models & Make-up: Maria Neagu & Teodora Gheorghe
      </p>
      <p className={styles.description}>
        Creative direction & Designs: Flavia Nistor
      </p>
    </>
  );
};

export default ToBrancusi;
